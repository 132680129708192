<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import { useWindowHelpers } from '~/composables/window-helpers'

const props = withDefaults(
  defineProps<{
    buttonColor?: 'gray' | 'light-gray' | 'white'
    iconName: string
    isIconOnly?: boolean
    isOnProductCard?: boolean
    position?: 'left' | 'right'
    width?: string
  }>(),
  { buttonColor: 'gray', isIconOnly: true, isOnProductCard: false, position: 'right', width: 'auto' }
)

const { detectOutsideClick } = useWindowHelpers()

const componentRef = ref()
const showDropdownMenu = ref(false)

detectOutsideClick(componentRef, (): void => {
  showDropdownMenu.value = false
})

function toggle (): void {
  showDropdownMenu.value = !showDropdownMenu.value
}
</script>

<template>
  <div ref="componentRef" class="dropdown-menu inline-flex cursor-pointer items-center align-top" @click="toggle">
    <BasicButton
      aria-label="Открыть выпадающий список"
      :color="buttonColor"
      :icon="iconName"
      :is-blue-on-hover="isOnProductCard"
      :is-icon-only="isIconOnly"
      is-small
    />

    <div v-if="$slots.label" class="dropdown-menu__label">
      <slot name="label" />
    </div>

    <ul v-if="showDropdownMenu" class="dropdown-menu__items bg-white" :class="position" :style="{ width }">
      <slot />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

$icon-size: 24px;
$position-after: 8px;
$position-before: 10px;

.dropdown-menu {
  font-size: 16px;
  min-height: $icon-size;
  position: relative;

  &__items {
    border: 1px solid mix($main-gray-color, white, 20%);
    border-radius: 8px;
    box-shadow: 0 6px 10px rgba($main-gray-color, 20%);
    max-width: 300px;
    padding: 8px 0;
    position: absolute;
    top: 32px;
    z-index: 3;

    &:after,
    &:before {
      content: '';
      position: absolute;
    }

    &:after {
      $border-width: 7px;

      border-bottom: $border-width solid mix($main-gray-color, white, 20%);
      border-left: $border-width solid transparent;
      border-right: $border-width solid transparent;
      top: -$border-width;
    }

    &:before {
      $border-width: 5px;

      border-bottom: $border-width solid white;
      border-left: $border-width solid transparent;
      border-right: $border-width solid transparent;
      top: -$border-width;
      z-index: 2;
    }

    &.left {
      left: 0;

      &:after {
        left: $position-after;
      }

      &:before {
        left: $position-before;
      }
    }

    &.right {
      right: 0;

      &:after {
        right: $position-after;
      }

      &:before {
        right: $position-before;
      }
    }
  }

  &__label {
    padding-left: 4px;
  }
}

@media screen and (max-width: 600px) {
  td > .dropdown-menu > .dropdown-menu__items {
    left: 0;
    right: auto;

    &:before {
      left: $position-before;
      right: auto;
    }

    &:after {
      left: $position-after;
      right: auto;
    }
  }
}
</style>
